.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 155px 16px 136px;
    box-sizing: border-box;
    background: #2D6956;
    margin: 80px 0 0;
}

.footer__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 67px;
    text-align: center;

    color: #FFFFFF;
    margin: 0;
    max-width: 448px;
}


.footer__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 48px 0 0;
    height: 52px;
}

.footer__btn {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid #FFFFFF;
    border-radius: 7px;

    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    gap: 10px;
}

.footer__btn:last-of-type {
    margin: 0;
}

.footer__btn_disabled {
    pointer-events: none;
    /* opacity: 0.3; */
    border: 1px solid rgba(255, 255, 255, 0.3);
}


.footer__socials {
    display: flex;
    flex-direction: row;
    margin: 80px 0 0;
}

.footer__social {
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 54px;
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px 0 0;
}

.footer__social:last-of-type {
    margin: 0;
}



@media (max-width: 1400px) {




    .footer__btns {
        margin: 24px 0 0;
    }
}

@media (max-width: 660px) {

    .footer__title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 41px;
    }

    .footer {
        padding: 32px;
    }

    .footer__btns {
        margin: 32px 0 0;
        width: 100%;
        flex-direction: column;
        height: unset;
        /* max-width: 180px; */
        box-sizing: border-box;
    }

    .footer__btn {
        width: 100%;
        margin: 8px 0 0;
        height: 52px;
        font-size: 20px;
    }

    .footer__btn:last-of-type {
        margin: 8px 0 0;
    }

    .footer__btn:first-of-type {
        margin: 0;
    }



}