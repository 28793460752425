.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: -142px 0 0;
    position: relative;
    z-index: 0;
}

.main__content{
    padding: 0 120px;
 
}

@media (max-width: 1400px) {
    .main__content{
        padding: 0 60px;
    }
}

@media (max-width: 660px) {
    .main{
        margin: -62px 0 0;
    }
    .main__content{
        padding: 0 16px;
    }
}