.doctors-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 542px;
    grid-template-rows: max-content;
    column-gap: 42px;
    margin: 120px 0 0;
}

.doctors-data__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* or 73px */


    color: #191C1B;

}

.doctors-data__graph-box {
    display: flex;
    flex-direction: column;
    margin: auto 0 0;
}

.doctors-data__graph {
    width: 100%;
}

.doctors-data__graph-hint {
    margin: 24px 0 0;
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;

    color: #191C1B;

}

.doctors-data__graph-container {
    background: #F3F7F3;
    border-radius: 28px;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
}

.doctors-data__graph-svg {
    width: 100%;
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.doctors-data__graph-svg_active {
    opacity: 1;
}

.doctors-data__graph-names {
    display: flex;
    flex-direction: row;
    z-index: 2;
    margin: 34px 0 0;
    width: 100%;
    /* grid-area: content; */
    overflow: hidden;
}


.doctors-data__graph-names-carousel {
    width: 100%;
    display: flex;
    transform: translateX(calc(-100%));
    transition: transform 0.3s ease;
}

.doctors-data__graph-names-arousel_sliding {
    transition: none;
}

.doctors-data__graph-names-carousel_type_prev {
    transform: translateX(calc(2 * (-100%)));
}

.doctors-data__graph-names-carousel_type_next {
    transform: translateX(0%);
}

.doctors-data__graph-name {
    display: flex;
    flex-direction: row;

    flex: 1 0 100%;
    flex-basis: 100%;
    width: 100%;

    justify-content: center;
}

.doctors-data__graph-name-text {
    margin: 0;

    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */


    color: #191C1B;

}

.doctors-data__graph-name-icon {
    margin: 0 4px 0 0;
}

@media (max-width: 1300px) {
    .doctors-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: unset;
        grid-template-columns: unset;
        grid-template-rows: unset;
    }

    .doctors-data__graph-hint {
        max-width: 542px;

    }

    .doctors-data__title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 140%;
        max-width: 880px;
    }

    .doctors-data__graph-box {
        margin: 80px 0 0;
    }
}

@media (max-width: 660px) {
    .doctors-data {
        margin: 47px 0 0;

    }

    .doctors-data__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 140%;
    }

    .doctors-data__graph-box {
        margin: 40px 0 0;
        width: 100%;
    }

    .doctors-data__graph {
        width: 100%;
        max-width: unset;
        height: unset;
    }

    .doctors-data__graph-hint {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .doctors-data__graph-name-text {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
    }
    .doctors-data__graph-container{
        height: 180px;
    }
    .doctors-data__graph-svg{
        max-height: 107px;
    }
}