.recomended {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 175px 0 0;
}

.recomended__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* or 73px */

    text-align: center;

    color: #191C1B;



}

.recomended__img {
    width: 100%;
    max-width: 937.69px;

    margin: 40px auto 0;
    filter: drop-shadow(30px 40px 40px rgba(0, 0, 0, 0.25));
}

.recomended__cards {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 0 0 500px;

}

.recomended__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    box-sizing: border-box;

    border-radius: 28px;
    width: fit-content;

}

.recomended__card_red {
    background: #F9DBD7;
    width: 478.8px;
    position: absolute;

}

.recomended__card-text {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 160%;
    /* or 51px */

    display: flex;
    align-items: center;

    color: #3B0605;
    margin: 16px 0 0;
}

@media (max-width: 1100px) {
    .recomended {
        margin: 134px 0 0;
    }

    .recomended__title {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 140%;
    }
}

@media (max-width: 800px) {
    .recomended__title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        text-align: left;
    }
    .recomended{
        margin: 60px 0 0;
    }
    /* .recomended__img{
        width: 110%;
    } */
}