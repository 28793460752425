.road {
    width: 100%;
    display: grid;
    margin: 160px 0 0;
    grid-template-columns: 1fr 383px;
    grid-template-rows: max-content;
    column-gap: 90px;
}

.road__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 67px;

    color: #191C1B;
}

.road__cards {
    width: 100%;
    display: flex;
    flex-direction: row;

}

.road__cards-lines {
    width: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6px 4px 0 0;
}

.road__cards-lines-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #006B58;
}

.road__cards-line {
    border-left: 1.2px dashed #AEB7B0;
    height: 240px;
    width: 0px;
}

.road__cards-line_little {
    height: 50px;
}

.road__cards-box {
    display: flex;
    flex-direction: column;
    width: calc(100% - 12px - 4px);
}

.road__card {
    display: flex;
    flex-direction: column;
    margin: 185px 0 0;
}

.road__card:first-of-type {
    margin: 0;
}

.road__card:last-of-type {
    margin: 150px 0 0;
}

.road__card-date {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */


    color: #000000;
    margin: 0;
}

.road__card-text {
    margin: 4px 0 0;

    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height */


    color: #191C1B;

}

@media (max-width: 1100px) {
    .road {
        margin: 100px 0 0;
    }

    .road__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 41px;

    }

    .road__card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
    }

    .road__card {
        display: flex;
        flex-direction: column;
        margin: 190px 0 0;
    }

    .road {
        column-gap: 40px;
    }

    .road__card:first-of-type {
        margin: 0;
    }

    .road__card:last-of-type {
        margin: 160px 0 0;
    }
}

@media (max-width: 880px) {
    .road {
        grid-template-columns: 1fr;
        row-gap: 40px;

    }

    .road__cards {
        justify-content: center;
    }

    .road__card {
        width: fit-content;
    }

    .road__cards-box {
        width: fit-content;
    }

    .road__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
    }

    .road__card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
    }
}