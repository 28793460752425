.team {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    margin: 200px 0 0;
}

.team__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 67px;

    color: #191C1B;
}

.team__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, 345px);
    grid-template-rows: 480px;
    grid-auto-rows: 480px;
    column-gap: 24px;
    row-gap: 24px;
    margin: 40px 0 0;
}

.team__card {
    width: 100%;
    height: 100%;

    background: #D9D9D9;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.team__card-img {
    width: 100%;
    height: 100%;
    position: absolute;
    align-self: center;
    justify-self: center;
    z-index: 0;
    object-fit: cover;

}

.team__card-texts {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    box-sizing: border-box;
    width: 100%;
    margin: auto 0 0;
    position: relative;
    z-index: 1;
}

.team__card-name {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */


    color: #FFFFFF;
    margin: 0;
}

.team__card-title {
    margin: 8px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    /* identical to box height */


    color: #FFFFFF;

}

@media (max-width: 1100px) {
    .team {
        margin: 120px 0 0;
    }

    .team__title {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 56px;

    }
}

@media (max-width: 880px) {
    .team {
        margin: 100px 0 0;
        width: 100%;

    }

    .team__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
    }

    .team__cards {
        grid-template-columns: 1fr;
        grid-template-rows: 360px;
        grid-auto-rows: 360px;
    }

    .team__card-name {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }

    .team__card-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
}