.doctors-banner {
    background: radial-gradient(71.43% 67.12% at 0% 0%, #F9FF32 0%, #006B58 100%);
    border-radius: 0px 0px 52px 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 120px 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    min-height: 100vh;
}

.doctors-banner__texts {
    margin: 0 0 0;
    display: flex;
    flex-direction: column;
}

.doctors-banner__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 140%;
    /* or 101px */

    letter-spacing: 0.02em;

    color: #FFFFFF;
    margin: 0;
}


.doctors-banner__img {
    width: 420px;
    height: 420px;
}

.doctors-banner__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 110px 0 0;
    text-decoration: none;
    cursor: pointer;
}

.doctors-banner__link-text {
    margin: 0 8px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    /* identical to box height */


    color: #FFFFFF;

}

.doctors-banner__link-arrow {
    width: 45px;
    height: 8px;
    margin: 8px 0 0;
}

@media (max-width: 1400px) {
    .doctors-banner {
        padding: 185px 40px 0;
        flex-direction: column;
        align-items: center;
        justify-content: unset;
    }

    .doctors-banner__title {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 140%;
        /* or 62px */

        text-align: center;
        letter-spacing: 0.02em;
        max-width: 540px;
    }

    .doctors-banner__texts {
        margin: 0;
    }

    .doctors-banner__img {
        width: 420px;
        height: 420px;
    }

    .doctors-banner__link {
        margin: 20px auto 0;
    }


}

@media (max-width: 660px) {
    .doctors-banner {
        padding: 120px 32px 0;
    }

    .doctors-banner__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        /* or 45px */

        text-align: center;
        letter-spacing: 0.02em;
    }


    .doctors-banner__img {
        width: 240px;
        height: 240px;

    }

    .doctors-banner__link {
        margin: 24px auto 0;
    }

    .doctors-banner__link-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }
    .doctors-banner__link-arrow{
        width: 30px;
        margin: 4px 0 0;
    }
}