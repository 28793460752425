.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 60px 120px 0;
    position: relative;
    z-index: 10;
}

.header__logo {
    width: 75px;
    height: 82px;
    text-decoration: none;
}


.header__logo-svg {
    width: 100%;
    height: 100%;
}

.header__menu {
    width: 40px;
    cursor: pointer;
    height: 40px;
}

.header__logo-svg_invert{
    fill: #006B58;
}

@media (max-width: 1400px) {
    .header {
        padding: 60px 40px 0;
    }
}

@media (max-width: 660px) {
    .header {
        padding: 14px 16px 0;
    }

    .header__logo {
        width: 44px;
        height: 48.11px;
    }
}