.in-sync {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 73px 32px 163px;
    background: #F3F7F3;
    border-radius: 72px;
    margin: 120px 0 0;
    overflow: hidden;
}

.in-sync__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 67px;
    text-align: center;

    color: #191C1B;
    margin: 0 auto;
}

.in-sync__text {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    text-align: center;

    color: #191C1B;
    margin: 20px auto 0;
    max-width: 851px;
}

.in-sync__link {

    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    color: #FFFFFF;
    text-decoration: none;
    margin: 40px auto 0;
    padding: 12px 24px;
    background: #006B58;
    border-radius: 36px;
}

.in-sync__img-box {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 71px auto 0;
    width: 854px;
    height: 516px;

}

.in-sync__main-img {
    position: relative;
    z-index: 1;
    width: 854px;
    height: 516px;
    margin: 0 auto;
    filter: drop-shadow(269px 182px 130px rgba(41, 68, 27, 0.01)) drop-shadow(151px 102px 110px rgba(41, 68, 27, 0.05)) drop-shadow(67px 45px 81px rgba(41, 68, 27, 0.08)) drop-shadow(17px 11px 45px rgba(41, 68, 27, 0.1)) drop-shadow(0px 0px 0px rgba(41, 68, 27, 0.1));
}

.in-sync__stethoscope-img {
    position: absolute;
    top: 10px;
    left: -135px;
    z-index: 0;

    width: 270px;
    height: 447px;
}

@media (max-width: 1100px) {
    .in-sync {
        padding: 19px 20px 29px;
        margin: 100px 0 0;
    }

    .in-sync__title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 51px;
        text-align: center;
    }

    .in-sync__text {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
    }

    .in-sync__link {
        margin: 20px auto 0
    }

    .in-sync__img-box {
        margin: 49px auto 0;
        width: 613.97px;
        height: 371.18px;
    }

    .in-sync__main-img {
        width: 613.97px;
        height: 371.18px;
    }

    .in-sync__stethoscope-img {
        width: 194px;
        height: 322px;
        top: 0;
        left: -107px;
    }

}

@media (max-width: 660px) {
    .in-sync {
        padding: 20px 20px 23px;
        margin: 80px 0 0;
        border-radius: 24px;
    }

    .in-sync__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        text-align: left;
        margin: 0;
    }

    .in-sync__text {
        margin: 20px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
    }

    .in-sync__link {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        margin: 20px 0 0;
        width: fit-content;
    }

    .in-sync__img-box {
        width: 428.22px;
        height: 258.88px;
        margin: 38px 0 0 67px;
    }

    .in-sync__main-img {
        width: 428.22px;
        height: 258.88px;
    }

    .in-sync__stethoscope-img {
        width: 142px;
        height: 229px;
        left: -67px;
    }
}