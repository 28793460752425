.demo {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 120px 0 0;
}

.demo__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 140%;
    /* or 62px */


    color: #191C1B;
    max-width: 857px;
}

.demo__img-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 60px 0 0;
    overflow: hidden;

}

.demo__img {
    width: 100%;
    position: relative;
    z-index: 0;
    /* min-width: 880px; */


}

.demo__img-link {
    margin: 0;
    position: absolute;
    z-index: 2;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */


    color: #FFFFFF;
    padding: 16px 24px;
    box-sizing: border-box;
    width: fit-content;
    background: #006B58;
    border-radius: 40px;
    text-decoration: none;
    cursor: pointer;
}

.demo__img_mobile{
    display: none;
}

@media (max-width: 1100px) {
    .demo {
        margin: 160px 0 0;
    }

    .demo__title {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 140%;
        max-width: 677px;
    }

    .demo__img-link {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
    }
}

@media (max-width: 800px) {
    .demo {
        margin: 110px 0 0;
    }

    .demo__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 140%;
    }

    .demo__img-link {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
    }
}


@media (max-width: 500px) {
    .demo__img_desktop {
        display: none;
    }
    .demo__img_mobile{
        display: inline-block;
    }
}