.description {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 120px 0 0;
}

.description__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* or 73px */

    text-align: center;
    letter-spacing: 0.02em;

    color: #191C1B;

    max-width: 736px;
}

.description__subtitle {
    margin: 26px 0 0;
    max-width: 600px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    text-align: center;

    color: #000000;

}

.description__cards {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: max-content;
    width: 100%;
    grid-template-areas: 'one one one two two two 3 three three three';
    padding: 0 40px;
    box-sizing: border-box;
    row-gap: 16px;
    column-gap: 16px;
    margin: 80px 0 0;
}

.description__card {
    width: 100%;
    height: 100%;
    border: 1px dashed #AEB7B0;
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    padding: 20px 16px;
    box-sizing: border-box;
}

.description__card_1 {
    grid-area: one;
}


.description__card_2 {
    grid-area: two;
}


.description__card_3 {
    grid-area: three;
}

.description__card-title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    color: #191C1B;
    margin: 0;
}

.description__card-value {
    margin: 16px 0 0;
}

.description__lines-box {
    width: calc(100% - 40px * 2);
    width: calc(100% - 40px * 2);
    max-width: 1200px;

    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 16px auto 0;
}

.description__lines {}


.description__cards_desktop {}

.description__cards_mobile {
    display: none;
}

.description__lines_desktop {}

.description__lines_mobile {
    display: none;
}

.description__engine {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 240px;
    filter: drop-shadow(89px 110px 57px rgba(41, 68, 27, 0.01)) drop-shadow(50px 62px 48px rgba(41, 68, 27, 0.05)) drop-shadow(22px 28px 36px rgba(41, 68, 27, 0.09)) drop-shadow(6px 7px 20px rgba(41, 68, 27, 0.1)) drop-shadow(0px 0px 0px rgba(41, 68, 27, 0.1));
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;

}

.description__engine-bg {
    position: absolute;
    z-index: 0;
    width: 410px;
    height: 410px;

    background: radial-gradient(100% 100% at 100% 0%, #A1FF09 15.62%, #59E9DE 67.71%, #7CA1FF 100%);
    animation: 5s linear 0s infinite normal both running rotate;
}

.description__engine-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #000000;

    color: #000000;
    margin: auto 0 0;
    position: relative;
    z-index: 1;
    align-self: flex-start;



}

.description__result {
    width: 100%;
    padding: 44px 118px;
    box-sizing: border-box;
    background: #F3F7F3;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
}

.description__result-content {
    width: 100%;
    display: grid;
    grid-template-columns: 44px 1fr 44px;
    grid-template-rows: max-content;
    align-items: center;
    grid-template-areas: 'arrow-left content arrow-right';
    column-gap: 12px;
}


.description__carousel-wrapper {
    width: 100%;
    /* grid-area: content; */
    overflow: hidden;
    margin: 0 0 0;
    /* position: fixed; */
    z-index: 1;
}

.description__carousel {

    display: flex;
    transform: translateX(calc(-100%));
    transition: transform 1s ease;
}

.description__carousel_sliding {
    transition: none;
}

.description__carousel_type_prev {
    transform: translateX(calc(2 * (-100%)));
}

.description__carousel_type_next {
    transform: translateX(0%);
}

.description__carousel-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    flex-basis: 100%;
    width: 100%;
    align-items: center;
}

.description__result-arrow {
    width: 44px;
    height: 45px;
    cursor: pointer;
}

.description__result-arrow_left {
    grid-area: arrow-left;
}

.description__result-arrow_right {
    grid-area: arrow-right;
    transform: rotate(180deg);
}

.description__carousel-card-title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 160%;
    /* identical to box height, or 53px */


    color: #191C1B;
}

.description__carousel-card-img {
    margin: 24px 0 0;
    width: 320px;
    height: 320px;
}

.description__carousel-card-text {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    text-align: center;

    color: #000000;
    max-width: 636px;

}

.description__result-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 58px 0 0;
    width: 100%;
}

.description__result-step {
    width: 8px;
    height: 8px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    border: 1px solid #2D6956;
    border-radius: 50%;
    margin: 0 12px 0 0;
}

.description__result-step:last-of-type {
    margin: 0;
}

.description__result-step_active {
    background-color: #2D6956;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}


@media (max-width: 1100px) {
    .description__title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 140%;
    }

    .description__subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
    }


    .description__result {
        padding: 44px 32px;
    }

    .description__result-content {
        grid-template-columns: 24px 1fr 24px;

    }

    .description__result-arrow {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 880px) {

    .description__cards_desktop {
        display: none;
    }

    .description__cards_mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        padding: 0;
        grid-template-areas: none;
    }

    .description__lines_desktop {
        display: none;
    }

    .description__lines_mobile {
        display: flex;
    }

    .description__cards {
        margin: 43px 0 0;
    }

    .description__card {
        padding: 12px 8px;
    }

    .description__card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
    }

    .description__card-value {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        margin: 8px 0 0;
    }

    .description__card-value:first-of-type {
        margin: 12px 0 0;
    }

    .description {
        margin: 44px 0 0;
    }

    .description__title {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 140%;
        /* or 42px */

        text-align: center;
        letter-spacing: 0.02em;
    }

    .description__subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
    }

    .description__lines-box {
        width: 100%;
    }

    .description__result {

        padding: 24px 0;
    }



    .description__carousel-card-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        height: 58px;
    }

    .description__carousel-card-img {
        margin: 8px 0 0;
        width: 206px;
        height: 206px;
    }

    .description__carousel-card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
    }
}