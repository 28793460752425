.take-care {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 94px 0 0;
}

.take-care__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 67px;

    color: #191C1B;
    max-width: 500px;
    margin: 0;
}

.take-care__row {
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
}

.take-care__row_1 {
    grid-template-columns: 1fr 1fr;
    margin: 40px 0 0;
}

.take-care__row_2 {
    margin: 24px 0 0;
    grid-template-columns: 1fr 1fr 251px;
}

.take-care__card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #F3F7F3;
    border-radius: 27px;
    padding: 32px;
    width: 100%;
    height: 100%;
}

.take-care__card-icon {
    width: 40px;
    height: 40px;
}

.take-care__card-title {
    margin: 32px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */


    color: #191C1B;

}

.take-care__card-text {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;

    color: #191C1B;
    margin: 16px 0 0;
}

.take-care__link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 21px 16px;
    background: #3F6F60;
    border-radius: 27px;
    text-decoration: none;
    box-sizing: border-box;
}

.take-care__link-title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;

    color: #FFFFFF;
    margin: 0;
    max-width: 219px;
}

.take-care__link-arrow {
    width: 121px;
    height: 9px;
    margin: auto 0 0 auto;
}

@media (max-width: 1100px) {
    .take-care__title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 51px;
    }

    .take-care__row {
        column-gap: 20px;
        row-gap: 16px;
    }

    .take-care__row_1 {
        margin: 34px 0 0;
    }

    .take-care__row_2 {
        margin: 16px 0 0;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'track help'
            'link link';
    }

    .take-care__link {
        grid-area: link;
        align-items: center;
    }

    .take-care__link-arrow {
        margin: 43px 0 0;
    }

    .take-care__link-title {
        max-width: unset;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        /* identical to box height */

        text-align: center;
    }

    .take-care__card-title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
    }

    .take-care__card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }

}

@media (max-width: 800px) {
    .take-care{
        margin: 80px 0 0;
    }
    .take-care__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 41px;

    }

    .take-care__row_1 {
        grid-template-columns: 1fr;
        row-gap: 12px;
    }

    .take-care__row_2 {
        grid-template-columns: 1fr;
        grid-template-areas: 'sd'
            'ds'
            'link'
        ;
        margin: 12px 0 0;
        row-gap: 12px;
    }

    .take-care__card {
        padding: 16px;
    }

    .take-care__link {
        padding: 16px;
    }

    .take-care__card-title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
    }

    .take-care__card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .take-care__link-title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
    }
}