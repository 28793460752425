.ai{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: -142px 0 0;
    position: relative;
    z-index: 0;
}

.ai__content{
    padding: 0 70px;
}

@media (max-width: 1400px) {
    .ai__content{
        padding: 0 60px;
    }
}

@media (max-width: 660px) {
    .ai{
        margin: -62px 0 0;
    }
    .ai__content{
        padding: 0 16px;
    }
}