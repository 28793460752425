.main-banner {
    background: radial-gradient(71.43% 67.12% at 0% 0%, #F9FF32 0%, #006B58 100%);
    border-radius: 0px 0px 52px 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 120px 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    min-height: 100vh;
}

.main-banner__texts {
    margin: 0 0 0;
    display: flex;
    flex-direction: column;
}

.main-banner__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 140%;
    /* or 101px */

    letter-spacing: 0.02em;

    color: #FFFFFF;
    margin: 0;
}

.main-banner__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 48px 0 0;
    height: 52px;
}

.main-banner__btn {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    border: 1px solid #FFFFFF;
    border-radius: 7px;

    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;

    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    gap: 10px;
}

.main-banner__btn:last-of-type {
    margin: 0;
}

.main-banner__btn_disabled {
    pointer-events: none;
    /* opacity: 0.3; */
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.main-banner__img {
    width: 581px;
    height: 711px;
    margin: auto 0 0;
}

@media (max-width: 1400px) {
    .main-banner {
        padding: 185px 40px 0;
        flex-direction: column;
        align-items: center;
    }

    .main-banner__title {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 140%;
        /* or 62px */

        text-align: center;
        letter-spacing: 0.02em;
    }

    .main-banner__texts {
        margin: 0;
    }

    .main-banner__img {
        width: 329px;
        height: 402.61px;
    }

    .main-banner__btns {
        margin: 24px 0 0;
    }
}

@media (max-width: 660px) {
    .main-banner {
        padding: 120px 32px 0;
    }

    .main-banner__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        /* or 45px */

        text-align: center;
        letter-spacing: 0.02em;
    }

    .main-banner__btns {
        margin: 32px 0 0;
        width: 100%;
        flex-direction: column;
        height: unset;
    }

    .main-banner__btn {
        width: 100%;
        margin: 8px 0 0;
        height: 52px;
        font-size: 20px;
    }

    .main-banner__btn:last-of-type {
        margin: 8px 0 0;
    }

    .main-banner__btn:first-of-type {
        margin: 0;
    }

    .main-banner__img {
        width: 256px;
        height: 313.28px;
    }
}