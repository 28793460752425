.alghoritm {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 132px 0 0;
}

.alghoritm__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 140%;
    /* or 62px */

    text-align: center;

    color: #191C1B;
}

.alghoritm__text {
    margin: 32px auto 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    text-align: center;

    color: #191C1B;
    max-width: 636px;
}

.alghoritm__image {
    width: 100%;
    max-width: 667px;
    /* height: 632px; */
    margin: 20px auto 0;
}

@media (max-width: 1100px) {
    .alghoritm {
        margin: 112px 0 0;
    }

    .alghoritm__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
    }

}

@media (max-width: 880px) {
    .alghoritm {
        margin: 68px 0 0;
    }

    .alghoritm__title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
    }

    .alghoritm__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        margin: 20px 0 0;
    }
    
}