@font-face {
    font-family: 'Lora';
    src: url('Lora-BoldItalic.eot');
    src: url('Lora-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lora-BoldItalic.woff2') format('woff2'),
        url('Lora-BoldItalic.woff') format('woff'),
        url('Lora-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-Bold.eot');
    src: url('Lora-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lora-Bold.woff2') format('woff2'),
        url('Lora-Bold.woff') format('woff'),
        url('Lora-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-Italic.eot');
    src: url('Lora-Italic.eot?#iefix') format('embedded-opentype'),
        url('Lora-Italic.woff2') format('woff2'),
        url('Lora-Italic.woff') format('woff'),
        url('Lora-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-Medium.eot');
    src: url('Lora-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lora-Medium.woff2') format('woff2'),
        url('Lora-Medium.woff') format('woff'),
        url('Lora-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-MediumItalic.eot');
    src: url('Lora-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Lora-MediumItalic.woff2') format('woff2'),
        url('Lora-MediumItalic.woff') format('woff'),
        url('Lora-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-Regular.eot');
    src: url('Lora-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lora-Regular.woff2') format('woff2'),
        url('Lora-Regular.woff') format('woff'),
        url('Lora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-SemiBoldItalic.eot');
    src: url('Lora-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lora-SemiBoldItalic.woff2') format('woff2'),
        url('Lora-SemiBoldItalic.woff') format('woff'),
        url('Lora-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lora';
    src: url('Lora-SemiBold.eot');
    src: url('Lora-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Lora-SemiBold.woff2') format('woff2'),
        url('Lora-SemiBold.woff') format('woff'),
        url('Lora-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff'),
        url('Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

