.about-us{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: -142px 0 0;
    position: relative;
    z-index: 0;
}

.about-us__content{
    padding: 0 70px;
 
}

@media (max-width: 1400px) {
    .about-us__content{
        padding: 0 60px;
    }
}

@media (max-width: 660px) {
    .about-us{
        margin: -62px 0 0;
    }
    .about-us__content{
        padding: 0 16px;
    }
}