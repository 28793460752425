.about-banner {
    border-radius: 0px 0px 52px 52px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 120px 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    min-height: 100vh;
}


.about-banner__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* or 73px */
    
    letter-spacing: 0.01em;
    
    color: #191C1B;
    margin: 0;
}


.about-banner__img {
    width: 420px;
    height: 420px;

}

@media (max-width: 1400px) {
    .about-banner {
        padding: 0 40px 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about-banner__title {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 140%;
    }

    .about-banner__img {
        margin: 20px 0 0;
        width: 343px;
        height: 343px;
    }



}

@media (max-width: 660px) {
    .about-banner {
        padding: 0 32px 0;
    }

    .about-banner__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 140%;
       letter-spacing: 0.01em;
    }



    .about-banner__img {
        width: 240px;
        height: 240px;
    }
}