.meet {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 120px 0 0;
}

.meet__heading {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    /* identical to box height */


    color: #006B58;

    margin: 0;
}

.meet__text {
    margin: 12px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;

    color: #191C1B;
    max-width: 885px;
}

.meet__cards {
    margin: 60px 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    column-gap: 40px;
}

.meet__card {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.meet__card-icon {
    width: 32px;
    height: 32px;
}

.meet__card-text {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;

    color: #191C1B;
    margin: 11px 0 0;
}

@media (max-width: 1100px) {
    .meet {
        margin: 80px 0 0;
    }

    .meet__heading {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }

    .meet__text {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;

    }

    .meet__cards {
        margin: 44px 0 0;
    }
}

@media (max-width: 880px) {
    .meet {
        margin: 42px 0 0;
    }

    .meet__heading {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .meet__text {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
    }

    .meet__cards {
        grid-template-columns: 1fr;
        row-gap: 28px;
    }

    .meet__card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
    }
}