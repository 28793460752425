.not-fond {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    width: 100%;
}

.not-fond__title {

    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 308px;
    line-height: 140%;
    /* or 431px */

    letter-spacing: 0.02em;

    color: #016C59;

    margin: 0;
}

.not-fond__subtitle {
    margin: 20px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* identical to box height, or 101px */

    letter-spacing: 0.02em;

    color: #016C59;


}

.not-fond__link{
    margin: 12px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 140%;
    /* identical to box height, or 101px */

    letter-spacing: 0.02em;

    color: #016C59;

}

@media (max-width: 880px) {

    .not-fond__title{
        font-size: 108px;
        line-height: 140%;
    }
    .not-fond__subtitle{
        font-size: 28px;
        line-height: 140%;
    }
    .not-fond__link{
        font-size: 24px;
        line-height: 140%;
    }
}