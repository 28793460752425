.introduction {
    display: flex;
    flex-direction: column;
    margin: 156px 0 0;
    width: 100%;
}

.introduction__heading {
    margin: 0 auto;
    width: fit-content;
    box-sizing: border-box;
    padding: 24px;

    background: #F3F7F3;
    border-radius: 19px;


    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;

    color: #191C1B;

    max-width: 736px;
}

.introduction__slides {
    width: calc(100% - 172px - 61px);
    margin: 269px 61px 0 172px;
    display: flex;
    flex-direction: column;
}

.introduction__slides-on-pc-box {
    display: flex;
    flex-direction: row;
    width: calc(100% - 172px - 61px);
    margin: 269px 61px 0 172px;
    position: relative;
    height: fit-content;
    justify-content: space-between;
}

.introduction__slides-on-pc {

    display: flex;
    flex-direction: column;

}

.introduction__slide {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0 0;

}

.introduction__slide_pc {
    height: 760px;
}

.introduction__slide:first-of-type {
    margin: 0;
}

.introduction__slide-text-box {
    display: flex;
    flex-direction: row;
    /* align-items: center; */


}

.introduction__slide-text-img {
    width: 65px;
    height: 166px;
    margin: 32px 24px 0 0;
}

.introduction__slide-text-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 7px 14px 17px;

    background: #F3F7F3;
    border-radius: 15px;
    width: 100%;
    max-width: 415px;
    position: relative;
    height: fit-content;

}

.introduction__slide-text {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* or 29px */


    color: #000000;
    position: relative;
    z-index: 2;

}

.introduction__slide-text-corner {
    width: 20px;
    height: 15px;
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -5px;
}

.introduction__slide-img {
    width: 404px;
    height: 760px;
}

.introduction__slide-img-box {
    position: sticky;
    height: fit-content;
    top: calc((100vh - 765px) / 2);
    padding: -40px 0 0;
}

.introduction__slides_mobile {
    display: none;
}

.introduction__slide-img-screen {
    position: absolute;
    left: 18.81px;
    top: 18.5px;
    transition: opacity 0.1s linear;
}

@media (max-width: 1400px) {
    .introduction__slides-on-pc-box {
        display: none;
    }

    .introduction {
        margin: 129px 0 0;
    }

    .introduction__slide {
        flex-direction: column-reverse;
        align-items: center;
        margin: 59px 0 0;
    }

    .introduction__slides_mobile {
        display: flex;
    }

    .introduction__slides {
        width: 100%;
        margin: 120px auto 0;
    }

    .introduction__slide-img {
        width: 224px;
        height: 421px;
        margin: 0 0 0 31px;
    }

    .introduction__slide-text-img {
        width: 40px;
        height: 101px;
    }

    .introduction__slide-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
    }

    .introduction__heading {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        padding: 15px;
        max-width: 634px;
    }

}

@media (max-width: 660px) {
    .introduction {
        margin: 40px 0 0;
    }

    .introduction__slide-text-img {
        width: 32px;
        height: 81px;
    }

    .introduction__slide-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .introduction__slide-text-container {
        padding: 7px 14px;
    }

    .introduction__slides {
        margin: 56px 0 0;
    }


    .introduction__heading {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
    }
}