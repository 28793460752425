.menu-popup__container {
  width: 100vw;
  height: calc(100vh);
  background: #016C59;
  z-index: 1001;
  position: absolute;
  bottom: 0;
  top: 0;
  box-sizing: border-box;
  padding: 90px 120px;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 70px;
	transform: translateY(70px); */

  right: calc(-100vw);
  transition: transform 0.5s linear, background-color 0.6s linear;
}


.menu-popup__container_active::before {
  transition: transform 0.5s linear, background-color 0.6s linear;
  background: #016C59;
}


.menu-popup__container_active {
  transform: translateX(-100vw);
}

.menu-popup__background {
  width: 100%;
  height: 130vh;
  background: #000;
  opacity: 0;
  z-index: 999;
  transition: opacity 0.4s linear;
}

.menu-popup__background_active {
  opacity: 0.3;
  transition: opacity 0.4s linear;
}


.menu-popup {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  right: 0;

  width: 100vw;
  height: 130vh;
  visibility: hidden;
  transition: visibility 1s, opacity 1s linear;
}

.menu-popup-top {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;

  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom));
  visibility: hidden;
  transition: visibility 1s, opacity 1s linear;
}


.menu-popup_active {
  visibility: visible;
}

.menu-popup__close {
  border: none;
  background-color: transparent;
  width: fit-content;
  align-self: flex-end;
  padding: 0;
  cursor: pointer;
}

.menu-popup__close-icon {
  width: 32px;
  height: 32px;
}

.menu-popup__close-icon-fill {
  fill: #ffffff;

}

.menu-popup__nav-link {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 56px;

  color: #FFFFFF;
  padding-bottom: 8px;
  text-decoration: none;
  /* border-bottom: 1px solid transparent; */
  margin: 44px auto 0;
  width: fit-content;
}

.menu-popup__nav-link:first-of-type{
  margin: 22px auto 0;
}
.menu-popup__nav-link_active {
  text-decoration: underline;

}

@media (max-width: 880px) {
  .menu-popup__container{
    padding: 32px 12px;
  }
  .menu-popup__nav-link{
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    margin: 24px auto 0;
  }
}