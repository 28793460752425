.newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 120px 0 0;
}

.newsletter__title {
    margin: 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 67px;
    /* identical to box height */

    text-align: center;

    color: #191C1B;

}

.newsletter__text {
    margin: 20px 0 0;
    max-width: 475px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */

    text-align: center;

    color: #191C1B;
}

.newsletter__input-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 521px;
    align-items: center;
    margin: 40px 0 0;
}

.newsletter__input {
    -webkit-appearance: none;
    background-color: unset;
    font: unset;
    text-rendering: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    appearance: unset;
    font-style: normal;
    border: none;

    padding: 12px 8px;
    background: #F3F7F3;
    border-radius: 6px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */


    /* material-theme/sys/light/outline */

    color: #191C1B;
    margin: 0 8px 0 0;
    width: calc(100% - 124px - 8px);
    box-sizing: border-box;
}

.newsletter__input:focus {
    outline: none;
}

.newsletter__input::placeholder {
    color: #6F7974;
}


.newsletter__input-submit {
    background: #006B58;
    border-radius: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    box-sizing: border-box;

    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    border: none;

    color: #FFFFFF;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}

.newsletter__input-submit_valid {
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
}

@media (max-width: 1100px) {
    .newsletter__title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 51px;
    }
}


@media (max-width: 660px) {
    .newsletter__title {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
    }

    .newsletter__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        margin: 20px 0 0;
        text-align: left;
    }
    .newsletter__input-box{
        flex-direction: column;
    }
    .newsletter__input{
        width: 100%;
    }
    .newsletter__input-submit{
        margin: 8px 0 0;
        width: 100%;
    }
}