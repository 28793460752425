.reviews {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 120px 0 0;
}

.reviews__title {

    margin: 0;


    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 140%;
    /* or 73px */


    color: #191C1B;
    max-width: 1090px;
}


.reviews__result {
    width: 100%;
    padding: 44px 118px;
    box-sizing: border-box;
    background: #F3F7F3;
    border-radius: 36px;
    margin: 24px 0 0;
    display: flex;
    flex-direction: column;
}

.reviews__result-content {
    width: 100%;
    display: grid;
    grid-template-columns: 44px 1fr 44px;
    grid-template-rows: max-content;
    align-items: center;
    grid-template-areas: 'arrow-left content arrow-right';
    column-gap: 12px;
}


.reviews__carousel-wrapper {
    width: 100%;
    /* grid-area: content; */
    overflow: hidden;
    margin: 0 0 0;
    /* position: fixed; */
    z-index: 1;
}

.reviews__carousel {

    display: flex;
    transform: translateX(calc(-100%));
    transition: transform 1s ease;
}

.reviews__carousel_sliding {
    transition: none;
}

.reviews__carousel_type_prev {
    transform: translateX(calc(2 * (-100%)));
}

.reviews__carousel_type_next {
    transform: translateX(0%);
}

.reviews__carousel-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    flex-basis: 100%;
    width: 100%;
    align-items: center;
}

.reviews__result-arrow {
    width: 44px;
    height: 45px;
    cursor: pointer;
}

.reviews__result-arrow_left {
    grid-area: arrow-left;
}

.reviews__result-arrow_right {
    grid-area: arrow-right;
    transform: rotate(180deg);
}


.reviews__result-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 58px 0 0;
    width: 100%;
}

.reviews__result-step {
    width: 8px;
    height: 8px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    border: 1px solid #2D6956;
    border-radius: 50%;
    margin: 0 12px 0 0;
}

.reviews__result-step:last-of-type {
    margin: 0;
}

.reviews__result-step_active {
    background-color: #2D6956;
}

.reviews__carousel-card-text {
    margin: 0;
    max-width: 768px;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    text-align: center;

    color: #000000;
}

.reviews__carousel-card-img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin: 58px 0 0;
}

.reviews__carousel-card-name {
    margin: 20px 0 0;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */


    color: #000000;
}

.reviews__carousel-card-department {
    margin: 8px 0 0;

    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */


    color: #000000;

}


@media (max-width: 1100px) {

    .reviews {
        margin: 72px 0 0;

    }

    .reviews__result {
        padding: 44px 32px;
    }

    .reviews__result-content {
        grid-template-columns: 24px 1fr 24px;

    }

    .reviews__result-arrow {
        width: 24px;
        height: 24px;
    }

    .reviews__title {
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 140%;
    }
}

@media (max-width: 880px) {
    .reviews {
        margin: 20px 0 0;
    }

    .reviews__title {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
    }

    .reviews__result {

        padding: 24px 0;
    }

    .reviews__carousel-card-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
    }

    .reviews__carousel-card-name {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
    }

    .reviews__carousel-card-department {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
    }



}